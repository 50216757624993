@media print {
  .ProseMirror h1,
  .ProseMirror h2 {
    break-after: avoid;
  }

  .ProseMirror .codeblock {
    break-inside: avoid;
  }

  .ProseMirror img {
    border-radius: 0;
  }
}

.ProseMirror {
  --editor-max-width: 736px; /* 768px - 2 * 18px */
  --editor-padding: 0px;
  --editor-vertical-margin: 1.5rem;
  --editor-line-height: 1.5;

  height: 100%;
  width: 100%;
  outline: none;
  line-height: var(--editor-line-height);
  padding: var(--editor-padding);
}

.ProseMirror[contenteditable="false"] {
  font-variant-ligatures: contextual common-ligatures;
  font-feature-settings:
    "liga" 1,
    "calt" 1,
    "kern" 1,
    "case" 1,
    "ss03" 1,
    "cv01",
    "cv05",
    "cv06",
    "cv08",
    "cv10",
    "cv11"; /* by default, inter has calt, ccmp, kern on */
}

.ProseMirror > * {
  width: var(--editor-max-width);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror p {
  font-size: 18px;
  margin-block-start: var(--editor-vertical-margin);
  margin-block-end: var(--editor-vertical-margin);
  min-height: calc(var(--editor-line-height) * 1em);
}

.ProseMirror[contenteditable="true"] p.ProseMirror-emptynode:before {
  content: "paragraph";
}

.ProseMirror h1 + p,
.ProseMirror h2 + p,
.ProseMirror h3 + p,
.ProseMirror h4 + p,
.ProseMirror h5 + p,
.ProseMirror h6 + p {
}

.ProseMirror img {
  width: auto;
  max-width: calc(100% + 2 * var(--editor-padding));
  border-radius: 0.125rem;
  margin-top: calc(1.5 * var(--editor-vertical-margin));
  margin-bottom: calc(1.5 * var(--editor-vertical-margin));
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.ProseMirror h1 + img,
.ProseMirror h2 + img,
.ProseMirror h3 + img,
.ProseMirror h4 + img,
.ProseMirror h5 + img,
.ProseMirror h6 + img {
  margin-top: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror figure img {
  margin-top: var(--editor-vertical-margin);
  margin-bottom: var(--editor-vertical-margin);
}

.ProseMirror hr {
  border: none;
  margin: var(--editor-vertical-margin) auto;
  width: var(--editor-max-width);
}

.ProseMirror hr:not([data-type]) {
  height: 3px;
}

.ProseMirror[contenteditable="true"] hr {
  cursor: pointer;
}

.ProseMirror[contenteditable="true"] hr.ProseMirror-activenode {
  outline: 2px solid #7dd3fc;
}

.ProseMirror hr[data-type="---"] {
  padding-top: 4px;
  padding-bottom: 6px;
}

.ProseMirror hr[data-type="---"]:after {
  content: "";
  display: flex;
  height: 2px;
  width: 100%;
  background-color: theme("colors.gray.300");
}

.ProseMirror hr[data-type="___"] {
  padding-top: 4px;
  padding-bottom: 6px;
}
.ProseMirror hr[data-type="___"]:after {
  content: "";
  display: flex;
  height: 2px;
  width: 100%;
  background-color: theme("colors.gray.300");
}

.ProseMirror hr[data-type="***"] {
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProseMirror hr[data-type="***"]:after {
  content: "•••";
  padding-left: 20px;
  letter-spacing: 20px;
  font-size: 0.75rem;
  font-weight: bold;
}

.ProseMirror h1 + hr:not([data-type="***"]),
.ProseMirror h2 + hr:not([data-type="***"]),
.ProseMirror h3 + hr:not([data-type="***"]),
.ProseMirror h4 + hr:not([data-type="***"]),
.ProseMirror h5 + hr:not([data-type="***"]),
.ProseMirror h6 + hr:not([data-type="***"]) {
  margin-top: -2px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ProseMirror h1 + hr:not([data-type="***"]):after,
.ProseMirror h2 + hr:not([data-type="***"]):after,
.ProseMirror h3 + hr:not([data-type="***"]):after,
.ProseMirror h4 + hr:not([data-type="***"]):after,
.ProseMirror h5 + hr:not([data-type="***"]):after,
.ProseMirror h6 + hr:not([data-type="***"]):after {
  content: "";
  display: flex;
  height: 2px;
  background-color: theme("colors.gray.300");
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin-block-start: 1.25em;
  letter-spacing: -0.02em;
}

.ProseMirror h1 {
  font-size: 2.5rem;
  line-height: 2.75rem;
  letter-spacing: -0.0222em;
  font-weight: 600;
}

.ProseMirror h2 {
  font-size: theme("fontSize.3xl");
  line-height: 2.25rem; /* text-3xl */
  letter-spacing: -0.0218em;
  font-weight: 600;
}

.ProseMirror h3 {
  font-size: theme("fontSize.2xl");
  line-height: 2rem; /* text-2xl */
  letter-spacing: -0.0206em;
  font-weight: 500;
}

.ProseMirror h4 {
  font-size: theme("fontSize.xl");
  line-height: 1.75rem; /* text-xl */
  letter-spacing: -0.0186em;
  color: theme("colors.gray.500");
  font-weight: 500;
}

.ProseMirror h6 {
  font-size: theme("fontSize.sm");
  line-height: 1.25rem; /* text-sm */
  letter-spacing: -0.0105em;
  color: theme("colors.gray.500");
  font-weight: 500;
}

.ProseMirror h1 + h2 {
  font-weight: 400;
  letter-spacing: -0.02em;
}

.ProseMirror h1 + h3 {
  font-weight: 400;
}

.ProseMirror h1 + h2,
.ProseMirror h1 + h3,
.ProseMirror h1 + h4,
.ProseMirror h1 + h5,
.ProseMirror h1 + h6 {
  margin-top: 0rem;
  color: theme("colors.gray.500");
}

.ProseMirror h6 + h1,
.ProseMirror h6 + h2,
.ProseMirror h6 + h3 {
  margin-top: -0.25rem !important;
}

.ProseMirror img[data-align="cover"] + h1 {
  margin-block-start: 1rem;
}

.ProseMirror .codeblock,
.ProseMirror .mathblock {
  font-family: var(--mono-space);
  font-feature-settings:
    "liga" 1,
    "calt" 1;
  width: calc(var(--editor-max-width) + 2 * var(--editor-padding));
  max-width: 100%;
  background-color: var(--secondary-background-color);
  margin: var(--editor-vertical-margin) auto;
  padding: 1rem;
  overflow-x: auto;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 768px) {
  .ProseMirror .codeblock,
  .ProseMirror .mathblock {
    max-width: calc(100% + 2 * var(--editor-padding));
    margin: var(--editor-vertical-margin) calc(-1 * var(--editor-padding));
    border-radius: 0;
  }
}

.ProseMirror .codeblock.ProseMirror-selectednode,
.ProseMirror .mathblock.ProseMirror-selectednode {
  outline: none !important; /* remove default ProseMirror-selectednode outline */
  box-shadow: 0 0 0 2px theme("colors.sky.200");
}

.ProseMirror.ProseMirror.ProseMirror-focused
  .codeblock.ProseMirror-selectednode,
.ProseMirror.ProseMirror.ProseMirror-focused
  .mathblock.ProseMirror-selectednode {
  box-shadow: 0 0 0 2px theme("colors.sky.300");
}

.ProseMirror .mathblock .katex-editor {
  margin-bottom: 0;
}

.ProseMirror blockquote {
  --blockquote-border-width: 3px;
  padding-left: calc(1.25rem - var(--blockquote-border-width));
  border-left: var(--blockquote-border-width) solid currentColor;
  padding-right: 1.25rem;
  width: calc(var(--editor-max-width) + 2.5rem);
}

.ProseMirror blockquote p.ProseMirror-emptynode:before {
  content: "blockquote" !important;
  pointer-events: none;
  height: 0;
}

.ProseMirror blockquote[data-type="pullquote"] {
  --blockquote-border-width: 0;
  padding-left: 2rem;
  padding-right: 0rem;
  margin: calc(2 * var(--editor-vertical-margin)) auto;
  width: calc(var(--editor-max-width));
  max-width: 100%;

  font-size: 1.5rem;
  color: theme("colors.gray.500");
}

.ProseMirror blockquote[data-type="pullquote"] p.empty-node:before {
  content: "pullquote" !important;
}

.ProseMirror .ProseMirror-activenode {
  transition: background-color 0ms ease-in;
}

.ProseMirror[contenteditable="true"] .ProseMirror-selectednode {
  outline: 2px solid theme("colors.sky.300");
  outline-offset: 2px;
}

.ProseMirror[contenteditable="false"] .ProseMirror-selectednode {
  outline: none;
}

.ProseMirror[contenteditable="true"] li.ProseMirror-selectednode:after {
  border-color: theme("colors.sky.300");
}

.ProseMirror h1 strong {
  font-weight: 800;
}
.ProseMirror h3 strong {
  letter-spacing: 0;
}
.ProseMirror h2 strong,
.ProseMirror h4 strong,
.ProseMirror h5 strong,
.ProseMirror h6 strong {
  font-weight: 700;
  letter-spacing: -0.01em;
}

.ProseMirror figure {
  margin-top: var(--editor-vertical-margin);
  margin-bottom: var(--editor-vertical-margin);
  width: 100%;
}

.ProseMirror figcaption {
  color: theme("colors.gray.500");
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  max-width: var(--editor-max-width);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(-0.5 * var(--editor-vertical-margin));
}

.ProseMirror[contenteditable="false"] figcaption.ProseMirror-emptynode {
  display: none; /* hide empty nodes when not editing */
}

.ProseMirror h1.ProseMirror-emptynode:before {
  content: "Heading 1";
  color: theme("colors.gray.300");
}
.ProseMirror h2.ProseMirror-emptynode:before {
  content: "Heading 2";
  color: theme("colors.gray.300");
}
.ProseMirror h3.ProseMirror-emptynode:before {
  content: "Heading 3";
  color: theme("colors.gray.300");
}
.ProseMirror h4.ProseMirror-emptynode:before {
  content: "Heading 4";
}
.ProseMirror h5.ProseMirror-emptynode:before {
  content: "Heading 5";
}
.ProseMirror h6.ProseMirror-emptynode:before {
  content: "Heading 6";
}

.ProseMirror[contenteditable="true"]
  ul.item-list
  > li
  > p.ProseMirror-emptynode:before {
  content: "item";
  pointer-events: none;
  height: 0;
}

.ProseMirror[contenteditable="true"]
  ol.enum-list
  > li
  > p.ProseMirror-emptynode:before {
  content: "enum";
  pointer-events: none;
  height: 0;
}

.ProseMirror[contenteditable="true"] .todo-item p.ProseMirror-emptynode:before {
  content: "todo";
  pointer-events: none;
  height: 0;
}

.ProseMirror[contenteditable="true"]
  .todo-item[data-checked="true"]
  > .todo-content
  > p.ProseMirror-emptynode:before {
  content: "done";
}

.ProseMirror figcaption.ProseMirror-emptynode:before {
  content: "caption";
  float: left;
  pointer-events: none;
  height: 0;
}

.ProseMirror .ProseMirror-emptynode:before {
  color: theme("colors.gray.400");
}

.ProseMirror :not(.codeblock):not(.mathblock).ProseMirror-emptynode:before {
  float: left;
  pointer-events: none;
  height: 0;
}

.ProseMirror .codeblock.ProseMirror-emptynode code:before,
.ProseMirror .codeblock code:empty:before,
.ProseMirror .mathblock.ProseMirror-emptynode code:before,
.ProseMirror .mathblock code:empty:before {
  color: theme("colors.gray.400");
  pointer-events: none;
}

.ProseMirror .codeblock.ProseMirror-emptynode code:before,
.ProseMirror .codeblock code:empty:before {
  content: "codeblock";
}

.ProseMirror .mathblock.ProseMirror-emptynode code:before,
.ProseMirror .mathblock code:empty:before {
  content: "mathblock";
}

.ProseMirror .mathblock.ProseMirror-emptynode code:before,
.ProseMirror .codeblock.ProseMirror-emptynode code:before {
  position: absolute;
}

.ProseMirror a {
  color: var(--link-color);
  text-decoration: underline;
  word-break: break-word;
}

.ProseMirror a[data-internal-link] {
  border-radius: 0.25rem;
  padding: 0.2em 0.4em;
  background-color: rgba(186, 230, 253, 0.5); /* sky-200 */
  color: var(--link-color);
  text-decoration: none;
}

.ProseMirror a[data-internal-link]:hover {
  text-decoration: underline;
}

.ProseMirror[contenteditable="false"] a {
  cursor: pointer;
}

.ProseMirror s {
  color: theme("colors.gray.400");
  text-decoration: line-through;
}

.ProseMirror ul.todo-list {
  padding-inline-start: 0;
  margin-top: var(--editor-vertical-margin);
  margin-bottom: var(--editor-vertical-margin);
}

.ProseMirror li {
  margin-block-start: calc(0.5 * var(--editor-vertical-margin));
  margin-block-end: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror li:first-child,
.ProseMirror li:first-child > :first-child {
  margin-block-start: 0;
}

.ProseMirror li:last-child,
.ProseMirror li:last-child > :last-child {
  margin-block-end: 0;
}

.ProseMirror li.todo-item {
  display: flex;
  flex-direction: row;
}

.ProseMirror li.todo-item .todo-checkbox {
  flex-shrink: 0;
  font-size: 1em;
  width: 1.25em;
  height: 1.25em;
  appearance: none;
  border-radius: 0.125em;
  cursor: pointer;
  margin: 0.125rem 0.25rem 0.125rem 0;
  border: 2px solid theme("colors.gray.400");
  user-select: none;
}

.ProseMirror li.todo-item .todo-checkbox:hover {
  border-color: theme("colors.gray.800");
}

.ProseMirror li.todo-item .todo-checkbox:before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
}

.ProseMirror li.todo-item .todo-checkbox:checked,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox {
  background: theme("colors.sky.400");
  border-color: theme("colors.sky.400");
}

.ProseMirror li.todo-item .todo-checkbox:focus,
.ProseMirror li.todo-item > .todo-checkbox:focus,
.ProseMirror li.todo-item .todo-checkbox:hover,
.ProseMirror li.todo-item > .todo-checkbox:hover {
  box-shadow: rgba(212, 212, 212, 0.5) 0px 0px 0px 2px;
}

.ProseMirror li.todo-item .todo-checkbox:checked:focus,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox:focus,
.ProseMirror li.todo-item .todo-checkbox:checked:hover,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox:hover {
  box-shadow: rgb(125, 211, 252) 0px 0px 0px 2px;
}

.ProseMirror li.todo-item .todo-checkbox:checked:before,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox:before {
  content: "✓";
  background-color: theme("colors.sky.400");
  color: white;
}

.ProseMirror li.todo-item .todo-checkbox:after,
.ProseMirror li.todo-item > .todo-checkbox:after {
  content: "";
  background-color: theme(colors.transparent);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: theme(borderRadius.full);
  margin: -26px -10px;
}

.ProseMirror li.todo-item .todo-checkbox:hover:checked:after,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox:hover:after {
  background-color: theme(colors.sky.500 / 10%);
}

.ProseMirror li.todo-item .todo-checkbox:active:checked:after,
.ProseMirror li.todo-item[data-checked="true"] > .todo-checkbox:active:after {
  background-color: theme(colors.sky.500 / 20%);
}

.ProseMirror li.todo-item .todo-checkbox:hover:not(:checked):after,
.ProseMirror li.todo-item[data-checked="false"] > .todo-checkbox:hover:after {
  background-color: theme(colors.gray.500 / 10%);
}

.ProseMirror li.todo-item .todo-checkbox:active:not(:checked):after,
.ProseMirror li.todo-item[data-checked="false"] > .todo-checkbox:active:after {
  background-color: theme(colors.gray.500 / 20%);
}

.ProseMirror .todo-content > :first-child {
  margin-block-start: 0;
  margin-block-end: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror .todo-content > :last-child {
  margin-block-start: calc(0.5 * var(--editor-vertical-margin));
  margin-block-end: 0;
}

.ProseMirror li.todo-item .todo-content {
  flex: 1;
  transition: color 25ms ease-in;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  overflow-x: clip;
}

.ProseMirror li.todo-item .todo-checkbox:checked + .todo-content > :first-child,
.ProseMirror li.todo-item[data-checked="true"] > .todo-content > :first-child {
  text-decoration: line-through;
}

.ProseMirror li.list-item > p,
.ProseMirror .todo-content > p {
  margin-block-start: calc(0.5 * var(--editor-vertical-margin));
  margin-block-end: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror li.todo-item .todo-checkbox:checked + .todo-content > :first-child,
.ProseMirror
  li.todo-item[data-checked="true"]
  > .todo-checkbox:checked
  + .todo-content
  > :first-child {
  text-decoration: line-through;
  text-decoration-color: theme("colors.gray.400");
}

.ProseMirror li.todo-item[data-checked="true"] .todo-content > * {
  color: theme("colors.gray.400");
}

.ProseMirror li.todo-item[data-checked="true"] .todo-content > * > a {
  opacity: 0.5;
}

/*
.ProseMirror li.todo-item .todo-content > :first-child {
  margin-block-end: calc(0.50 * var(--editor-vertical-margin));
}

.ProseMirror li.todo-item .todo-content > :last-child {
  margin-block-start: calc(0.50 * var(--editor-vertical-margin));
}
*/

.ProseMirror li ul.item-list,
.ProseMirror li ul.todo-list,
.ProseMirror li ol.enum-list {
  margin-top: calc(0.5 * var(--editor-vertical-margin));
  margin-bottom: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror li.list-item > p:only-child,
.ProseMirror li.todo-item .todo-content > p:only-child {
  margin: 0;
}

.ProseMirror code {
  font-family: var(--mono-space);
}

.ProseMirror p code,
.ProseMirror h1 code,
.ProseMirror h2 code,
.ProseMirror h3 code,
.ProseMirror h4 code,
.ProseMirror h5 code,
.ProseMirror h6 code {
  font-size: 0.875em;
  padding: 0.2em 0.375em;
  margin: 0;
  background-color: var(--secondary-background-color);
  color: black;
  border-radius: 0.25rem;
}

.ProseMirror mark {
  background-color: theme("colors.yellow.300");
}

.ProseMirror .codeblock code,
.ProseMirror .mathblock code {
  position: relative;
  white-space: pre;
  word-wrap: normal;
  color: black;
}

.ProseMirror pre.codeblock[data-lang]:before,
.ProseMirror pre.mathblock:before,
.ProseMirror pre.katex-editor:before {
  position: sticky;
  inset: 0;
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: var(--sans-serif);
  color: theme("colors.gray.400");
  padding-bottom: 0.25rem;
  height: 1.25rem;
}

.ProseMirror pre.codeblock[data-lang]:before {
  content: attr(data-lang);
}

.ProseMirror pre.katex-editor:before,
.ProseMirror pre.mathblock:before {
  content: "math";
}

.ProseMirror ul.item-list,
.ProseMirror ol.enum-list {
  padding-inline-start: 1.5rem;
  margin-top: var(--editor-vertical-margin);
  margin-bottom: var(--editor-vertical-margin);
}
.ProseMirror ul.item-list {
  list-style: disc;
}
.ProseMirror ol.enum-list {
  list-style: decimal;
}

.ProseMirror[contenteditable="false"] > *:first-child {
  margin-top: 0;
}

.ProseMirror.ProseMirror-focusmode {
  transition: color 50ms ease-in-out;
}
.ProseMirror.ProseMirror-focusmode *:not(.ProseMirror-activenode) {
  color: theme("colors.gray.300");
}
.ProseMirror.ProseMirror-focusmode .ProseMirror-activenode,
.ProseMirror.ProseMirror-focusmode .ProseMirror-activenode * {
  color: #000;
}

.ProseMirror li > :first-child,
.ProseMirror li > :last-child {
  margin-block-start: calc(0.5 * var(--editor-vertical-margin));
  margin-block-end: calc(0.5 * var(--editor-vertical-margin));
}

.ProseMirror img[data-align="center"] {
  width: 100%;
  max-width: var(--editor-max-width);
}

.ProseMirror img[data-align="breakout"] {
  width: 1024px;
  max-width: 100%;
}

.ProseMirror img[data-align="cover"] {
  width: calc(100% + 2 * var(--editor-padding));
  margin-left: calc(-1 * var(--editor-padding));
  margin-right: calc(-1 * var(--editor-padding));
  max-width: calc(100% + 2 * var(--editor-padding));
  border-radius: 0;
}

.ProseMirror-image-toolbar {
  position: absolute;
  z-index: 10;
  width: fit-content;
  left: 0px;
  right: 0px;
  margin: auto; /* horizontally center toolbar */
  transform: translateY(50%);
  border-radius: 0.25rem;
  color: theme("colors.white");
  background: theme("colors.gray.800");
}

.ProseMirror-image-toolbar button {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0.25rem;
  height: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ProseMirror-image-toolbar > button:first-of-type {
  padding-left: 0.5rem;
}
.ProseMirror-image-toolbar > button:last-of-type {
  padding-right: 0.5rem;
}

.ProseMirror-image-toolbar button:hover {
  color: theme("colors.gray.300");
}
.ProseMirror-image-toolbar button.ProseMirror-menu-active {
  color: theme("colors.sky.300");
}

.ProseMirror[contenteditable="true"] img.ProseMirror-selectednode,
.ProseMirror[contenteditable="true"] img.ProseMirror-activenode {
  outline: none; /* remove default ProseMirror-selectednode outline */
  box-shadow:
    0 0 0 2px #ffffff,
    0 0 0 4px theme("colors.sky.200"); /* sky 200 */
  border-radius: 0;
}

.ProseMirror.ProseMirror-focused[contenteditable="true"]
  img.ProseMirror-selectednode,
.ProseMirror.ProseMirror-focused[contenteditable="true"]
  img.ProseMirror-activenode {
  box-shadow:
    0 0 0 2px #ffffff,
    0 0 0 4px theme("colors.sky.300"); /* sky 300 */
}

.ProseMirror .katex-render {
  cursor: pointer;
  user-select: none;
}

.ProseMirror[contenteditable="true"] .katex-render {
  user-select: none;
  padding-top: 1rem;
}

.ProseMirror[contenteditable="false"] .katex-render .katex {
  user-select: none;
}

.ProseMirror[contenteditable="false"] .katex-editor {
  display: none;
}

.ProseMirror[contenteditable="false"] .katex-display {
  margin: 0;
}

.ProseMirror .katex-render .katex-error {
  color: theme("colors.red.500");
}

.ProseMirror .katex-display {
  margin: 0;
}

.ProseMirror[contenteditable="false"] .mathblock {
  background-color: transparent;
  padding: 1rem;
  border-radius: 0;
  width: var(--editor-max-width);
}

.ProseMirror[contenteditable="false"] .mathblock.ProseMirror-activenode {
  background-color: var(--selection-color);
}

.ProseMirror[contenteditable="false"] .mathblock .katex-render {
  margin-bottom: 0;
}

.ProseMirror[contenteditable="false"] img.ProseMirror-activenode {
  outline: none; /* remove default ProseMirror-selectednode outline */
  box-shadow:
    0 0 0 2px #ffffff,
    0 0 0 4px var(--selection-color, theme("colors.sky.200"));
  border-radius: 0;
}

@media (max-width: 768px) {
  .ProseMirror h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.02195em;
  }

  .ProseMirror h2 {
    font-size: 1.5rem;
    line-height: 2rem; /* text-2xl */
    letter-spacing: -0.02064em;
  }

  .ProseMirror h3 {
    font-size: theme("fontSize.xl");
    line-height: 1.75rem; /* text-xl */
    letter-spacing: -0.01865em;
    font-weight: 500;
  }
}

.ProseMirror-dropcursor {
  pointer-events: none;
}

.ProseMirror-gapcursor {
  left: var(--editor-padding);
  right: var(--editor-padding);
  max-width: calc(100% - 2 * var(--editor-padding));
  pointer-events: none;
}

.ProseMirror .ProseMirror-gapcursor:first-child + * {
  margin-top: 1rem;
}

.feed {
  width: 100%;
}
.feed .ProseMirror {
  padding: 0 var(--editor-padding);
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  font-variant-ligatures: contextual common-ligatures;
  font-feature-settings:
    "liga" 1,
    "calt" 1,
    "kern" 1,
    "case" 1,
    "ss03" 1,
    "cv01",
    "cv05",
    "cv06",
    "cv08",
    "cv10",
    "cv11"; /* by default, inter has calt, ccmp, kern on */
}

.feed .ProseMirror h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.02195em;
}
.feed .ProseMirror h2 {
  font-size: 1.5rem;
  line-height: 2rem; /* text-2xl */
  letter-spacing: -0.02064em;
}
.feed .ProseMirror h3 {
  font-size: theme("fontSize.xl");
  line-height: 1.75rem; /* text-xl */
  letter-spacing: -0.01865em;
  font-weight: 500;
}
.feed .ProseMirror h1,
.feed .ProseMirror h2,
.feed .ProseMirror h3,
.feed .ProseMirror h4,
.feed .ProseMirror h5,
.feed .ProseMirror h6 {
  margin-block-start: 1rem;
}
.feed .ProseMirror h6 + h1 {
  margin-block-start: -0.4375rem;
}
.feed .ProseMirror > p:empty {
  display: none;
}
.feed .ProseMirror h1 + h2,
.feed .ProseMirror h1 + h3,
.feed .ProseMirror h1 + h4,
.feed .ProseMirror h1 + h5,
.feed .ProseMirror h1 + h6 {
  margin-top: 0rem;
}
.feed .ProseMirror > ul {
  list-style: initial;
}
.feed .ProseMirror img {
  width: 100% !important;
  border-radius: 0.125rem;
  margin-top: calc(0.75 * var(--editor-vertical-margin));
  margin-bottom: calc(0.75 * var(--editor-vertical-margin));
  max-width: 100%;
}
.feed .ProseMirror figure img {
  max-width: 100%;
}
.feed .ProseMirror > :first-child {
  margin-top: 1rem;
}
.feed .ProseMirror > :last-child {
  margin-bottom: 0 !important;
}

.feed .ProseMirror blockquote {
  padding-right: 0;
  max-width: 100%;
}

.ProseMirror img:not([src]):not(.ProseMirror-separator) {
  height: 7.5rem;
  background: theme("colors.gray.50") url("../assets/ui-sprites.svg#image")
    no-repeat center / 1.25rem 1.25rem;
  cursor: pointer;
  content: "";
  border-color: transparent;
  border-radius: 0;
}

/*
.ProseMirror pre.codeblock.ProseMirror-cursornode:after {
  content: "exit: shift⏎";
  font-family: var(--sans-serif);
  color: theme("colors.gray.400");
  float: right;
  pointer-events: none;
  height: 0;
} */

.ProseMirror-linenumber.ProseMirror-widget {
  box-sizing: content-box;
  text-align: right;
  padding-right: 0.75rem;
  color: theme("colors.gray.400");
}

.ProseMirror[contenteditable="true"] li.ProseMirror-selectednode {
  outline: none;
}

.ProseMirror .katex-editor .cm-punctuation {
  color: theme("colors.gray.500");
}

.ProseMirror .katex-editor .cm-typeName {
}

.ProseMirror li.todo-item[data-checked="true"] > .todo-content > p:empty:after {
  content: "done";
}

.ProseMirror .ProseMirror-collabcursor {
  font-family: var(--sans-serif);
  position: relative;
  background: black;
  word-break: normal;
  pointer-events: none;
}
.ProseMirror .ProseMirror-collabcursor:before {
  content: "";
  position: absolute;
  top: 0;
  height: 1.25em;
  border-left: 1px solid var(--user-color);
}
.ProseMirror .ProseMirror-collabcursor div {
  position: absolute;
  top: -1.5em;
  left: 0px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: normal;
  user-select: none;
  color: black;
  background: var(--user-color);
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem 0.125rem 0.125rem 0; /* bottom left no radius */
  white-space: nowrap;
  visibility: visible;
}

/* mimick collaborative gapcursor when direct child of ProseMirror */

.ProseMirror > .ProseMirror-collabcursor {
  display: block;
}
.ProseMirror > .ProseMirror-collabcursor:before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  height: 1px;
  border-top: 1px solid var(--user-color);
  border-left: none;
}
.ProseMirror > .ProseMirror-collabcursor div {
  top: -2.5em;
}

/*
.ProseMirror a[href^='https://threads.brianhung.info/post/'], 
.ProseMirror a[href^='https://threads.brianhung.info/p/'],
.ProseMirror a[href^='/post/'], 
.ProseMirror a[href^='/p/'] {
  border-radius: 0.25rem;
  padding: 0.2em 0.4em;
  background-color: rgba(186, 230, 253, 0.50);
  color: #06c;
  text-decoration: none;
}

.ProseMirror a[href^='https://threads.brianhung.info/post/']:hover, 
.ProseMirror a[href^='https://threads.brianhung.info/p/']:hover,
.ProseMirror a[href^='/post/']:hover, 
.ProseMirror a[href^='/p/']:hover {
  text-decoration: underline;
}
*/
