:root {
  --sans-serif: theme("fontFamily.sans");
  --mono-space: theme("fontFamily.mono");
  --title-font: var(--sans-serif);

  --dark-font: theme("colors.white");
  --dark-background-color: theme("colors.gray.800");
  --dark-secondary-background-color: theme("colors.gray.700");

  --light-font: theme("colors.gray.800");
  --light-background-color: theme("colors.white");
  --light-secondary-background-color: theme("colors.gray.100");

  --font-color: var(--light-font);
  --background-color: var(--light-background-color);
  --secondary-background-color: var(--light-secondary-background-color);
  --link-color: #005fcc; /* -webkit-focus-ring-color:focus-visible */

  --selection-color: theme(colors.sky.200 / 75%);

  color-scheme: light; /* https://web.dev/color-scheme/ */
  font-feature-settings:
    "liga" 1,
    "calt" 1,
    "kern" 1,
    "case" 1,
    "ss03" 1,
    "cv01",
    "cv05",
    "cv06",
    "cv08",
    "cv10",
    "cv11"; /* by default, inter has calt, ccmp, kern on */
}

html,
body,
#__next {
  height: 100%;
}

html {
  color: var(--font-color);
  background-color: var(
    --background-color
  ); /* prevents color flash on page load */
  font-family: var(--sans-serif) !important;
  text-size-adjust: 100%;
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

#__next {
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  isolation: isolate;
}

::selection {
  background-color: var(--selection-color);
}

time {
  font-variant-numeric: tabular-nums;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
}

input:-webkit-autofill::first-line {
  font-size: 1rem !important;
  font-family: var(--sans-serif);
}

/* prevent default autofill colors */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 99999s;
  transition-property: background-color, color;
}

/*
@media (prefers-color-scheme:dark) {
  :root {
    --default-back: var(--dark-back);
    --font-color: var(--dark-font);
    --background-color: var(--dark-background-color);
    --secondary-background-color: var(--dark-secondary-background-color);
    --gray: var(--dark-gray);  
    --link-color: #2997ff;
    --selection-color: #B4D5FE;
  }
}
*/

a,
a:visited {
  color: var(--link-color);
}

a:focus-visible {
  outline: none;
  box-shadow: theme("colors.sky.300") 0px 0px 0px 2px;
  border-radius: theme("borderRadius.sm");
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0.5;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(00px);
  }
}

@media print {
  html,
  body {
    height: fit-content;
    overflow-y: visible;
  }

  html {
    font-size: 14px;
    width: 65ch;
    margin: auto;
  }

  :root {
    --dark-font: 255, 255, 255;
    --dark-background-color: #000000;
    --light-font: 00, 00, 00;
    --light-background-color: #ffffff;
    --link-color: var(--font-color);
  }

  a {
    text-decoration: none;
  }
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  outline: 0;
  transition-property: transform, visibility, opacity;
  background-color: transparent;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: theme("colors.gray.800");
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  z-index: 1;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: theme("transitionTimingFunction.in-out");
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  margin: auto 0;
  background: url("../assets/ui-sprites.svg#cancel") no-repeat 50% 50%;
  background-size: contain;
  cursor: pointer;
  opacity: 0.335; /* gray-400 */
}

button,
input,
optgroup,
select,
textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
}

input[type="checkbox"]:focus {
  --tw-ring-offset-width: 0px;
}
input[type="checkbox"]:checked {
  background-image: none;
}
input[type="checkbox"]:before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
input[type="checkbox"]:checked:before {
  content: "✓";
  color: white;
}
